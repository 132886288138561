.mailing {
    display: flex;
    flex-direction: column;
    padding: 47px 39px;

    background: #f1e0d8;
    min-height: 654px;
    margin-bottom: 24px;
}

.mailing__title {
    margin-bottom: 108px;

    font-weight: 300;
    font-size: 60px;
    line-height: 60px;
    font-family: 'Loos ExtraWide';
    color: #202020;
    letter-spacing: -5px;
}

.mailing__form {
    display: flex;
    flex-direction: column;
}

.mailing__input {
    width: 100%;
    margin-bottom: 12px;
    padding: 23px 26px;

    font-weight: 400;
    font-size: 18px;
    line-height: 18px;
    font-family: 'Loos ExtraWide';
    color: #202020;

    background: #ffffff;
    border-radius: 36px;
    border: 1px solid transparent;
}

.mailing__input._error {
    border: 1px solid red;
}

.mailing__input::placeholder {
    font-weight: 400;
    font-size: 18px;
    line-height: 18px;
    font-family: 'Loos ExtraWide';
    color: #202020;
}

.mailing__btn {
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 27px 0;

    font-weight: 400;
    font-size: 18px;
    line-height: 18px;
    font-family: 'Loos ExtraWide';
    color: #ffffff;

    background: #202020;
    border-radius: 36px;
    margin-bottom: 103px;
}

.mailing__label {
    display: flex;
    align-items: center;
    margin-top: auto;
}

.mailing__checkbox {
    position: relative;
    width: 21px;
    height: 21px;
    margin-right: 12px;

    background: transparent;
    border: 2px solid #000000;

    appearance: none;
    flex-shrink: 0;

    cursor: pointer;
}

.mailing__checkbox._error {
    border: 2px solid red;
}

.mailing__checkbox:checked:before {
    content: '';
    position: absolute;
    top: 2px;
    left: 2px;
    width: 13px;
    height: 13px;
    background: #000000;
}

.mailing__checkbox-text {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    font-family: 'Loos Normal';
    color: #202020;
}

.mailing__checkbox-text a {
    background: linear-gradient(#202020, #202020) no-repeat; 
    background-size: 100% 1px; 
    background-position: 0 100%; 
}

.mailing._success {
    display: flex;
    justify-content: center;
    align-items: center;
}

.mailing._success .mailing__title {
    margin-bottom: 0;
    font-weight: 300;
}

@media (max-width: 1452px) {
    .mailing {
        min-height: calc(454px + (654 - 454) * (100vw - 769px) / 1452);
    }

    .mailing__title {
        /* font-size: calc(48px + (60 - 48) * (100vw - 769px) / 1452);
        line-height: calc(48px + (60 - 48) * (100vw - 769px) / 1452);
        margin-bottom: calc(60px + (108 - 60) * (100vw - 769px) / 1452); */
        letter-spacing: calc(-2px + (-5 - -2) * (100vw - 769px) / 1452);
    }

    .mailing__btn {
        margin-bottom: calc(42px + (103 - 42) * (100vw - 769px) / 1452);
    }
}

@media (max-width: 768px) {
    .mailing {
        padding: calc(20px + (40 - 20) * (100vw - 375px) / 768);
    }
}

@media (min-width: 20em) and (max-width: 90em) {
    @supports (font-size: clamp(2rem, 1.5rem + 2.5vw, 3.75rem)) {
        .mailing__title {
            font-size: clamp(2rem, 1.5rem + 2.5vw, 3.75rem);
        }
    }
    @supports not (font-size: clamp(2rem, 1.5rem + 2.5vw, 3.75rem)) {
        .mailing__title {
            font-size: calc(2rem + 1.75 * (100vw - 20rem) / 70);
        }
    }
}

@media (max-width: 20em) {
    .mailing__title {
        font-size: 2rem;
    }
}

@media (min-width: 20em) and (max-width: 90em) {
    @supports (line-height: clamp(2rem, 1.5rem + 2.5vw, 3.75rem)) {
        .mailing__title {
            line-height: clamp(2rem, 1.5rem + 2.5vw, 3.75rem);
        }
    }
    @supports not (line-height: clamp(2rem, 1.5rem + 2.5vw, 3.75rem)) {
        .mailing__title {
            line-height: calc(2rem + 1.75 * (100vw - 20rem) / 70);
        }
    }
}

@media (max-width: 20em) {
    .mailing__title {
        line-height: 2rem;
    }
}
